import React, { useCallback } from 'react'
import { Type } from '@/components/common'
import { Comment, CommentReq } from '@/services/api/publicComments'
import CampaignCommentBody from './CampaignCommentBody'
import CampaignCommentForm from './CampaignCommentForm'

export interface Props extends Comment {
  campaignName: string
  postResponse?: (req: CommentReq) => Promise<Comment>
  className?: string
}

const CampaignComment: React.FC<Props> = ({
  id,
  name,
  text,
  created,
  answer,
  campaign,
  approved,
  financialTies,
  campaignName,
  postResponse,
  className,
}) => {
  const handleSubmit = useCallback(
    (userId: string, text: string) =>
      postResponse?.({ userId, text, parentId: id, campaign }),
    [id, campaign, postResponse]
  )

  return (
    <div className={className}>
      <div className="flex flex-col">
        <CampaignCommentBody
          name={name}
          created={created}
          text={text}
          approved={!!approved}
          campaignSlug={campaign}
        />

        {financialTies && (
          <Type as="p" variant="footer" className="mt-2 text-core-gray-700">
            Disclaimer: This user has financial ties to{' '}
            <span className="italic">{campaignName}</span>
          </Type>
        )}

        {answer && (
          <CampaignCommentBody
            campaignSlug={campaign}
            name={answer.name}
            created={answer.created}
            text={answer.text}
            approved={!!answer.approved}
            label={campaignName}
            className="mt-6 border-l-4 border-core-gray-200 pl-4"
          />
        )}

        {!answer && postResponse && (
          <CampaignCommentForm
            campaignSlug={campaign}
            onSubmit={handleSubmit}
            className="mt-3 lg:mt-4"
            placeholder="Reply to investor"
            isResponse
          />
        )}
      </div>
    </div>
  )
}

export default CampaignComment
