import qs from 'qs'
import client from '../thinMintClient'
import { resolveCommentDown } from './resolver'
import {
  ServerComment,
  ServerCommentList,
  Params,
  CommentReq,
  Comment,
} from './types'

export const baseEndpoint = '/campaign-public-comment'

export const getComments = (obj: Params) =>
  client
    .get<ServerCommentList>(
      `${baseEndpoint}/${qs.stringify(obj, { addQueryPrefix: true })}`
    )
    .then((res) => {
      const results = res.data.results.map(resolveCommentDown) as Comment[]
      return {
        ...res.data,
        results,
      }
    })

export const postComment = (obj: CommentReq) =>
  client
    .post(`${baseEndpoint}/`, {
      campaign: obj.campaign,
      text: obj.text,
      user: obj.userId,
    })
    .then(
      (res: { data: ServerComment }) => resolveCommentDown(res.data) as Comment
    )

export const postResponse = (obj: CommentReq) =>
  client
    .post(`${baseEndpoint}/`, {
      campaign: obj.campaign,
      text: obj.text,
      user: obj.userId,
      parent: obj.parentId,
      issuer_response: true,
    })
    .then(
      (res: { data: ServerComment }) => resolveCommentDown(res.data) as Comment
    )
