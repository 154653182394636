import React from 'react'
import formatDate from 'date-fns/format'
import { InfoPopover, Type } from '@/components/common'
import useCampaignAssets from '@/services/hooks/useCampaignAssets'
import Image from '../contentful/Image'

export interface Props {
  approved: boolean
  campaignSlug: string
  className?: string
  created: string
  label?: string
  name: string
  text: string
}

const CampaignCommentBody: React.FC<Props> = ({
  approved,
  campaignSlug,
  className,
  created,
  label,
  name,
  text,
}) => {
  const { campaignAssets } = useCampaignAssets(campaignSlug)

  return (
    <div className={className}>
      <header className="mb-1 flex items-center gap-3 text-core-gray-900">
        {label && campaignAssets?.squareImage && (
          <Image
            image={campaignAssets.squareImage}
            height={40}
            width={40}
            className="rounded-xl"
            containerClassName="shrink-0"
          />
        )}
        <div className="flex flex-col">
          {label && (
            <Type
              as="div"
              variant="title-xs"
              className="mb-1 text-core-gray-600"
            >
              {label} Team
            </Type>
          )}

          <div className="flex flex-wrap items-end gap-x-2 gap-y-1">
            <Type as="h4" variant="title-md" className="font-semibold">
              {name}
            </Type>

            <Type as="span" variant="title-xs" className="text-core-gray-600">
              {formatDate(new Date(created), 'MMM dd, yyyy')}
            </Type>
          </div>
        </div>
      </header>

      <Type as="p" variant="paragraph-md" className="break-words">
        {text}
      </Type>

      {!approved && (
        <Type
          as="span"
          variant="paragraph-lg"
          className="flex items-center gap-2 italic text-core-gray-500"
        >
          Sent to issuer. Pending approval
          <InfoPopover
            label="Pending Approval"
            className="text-core-gray-800"
            content={
              <>
                <Type
                  as="h3"
                  variant="title-xs"
                  className="mb-1 font-semibold text-white"
                >
                  Pending Approval
                </Type>
                <Type
                  as="p"
                  variant="paragraph-sm"
                  className="font-light text-white"
                >
                  This comment won&apos;t be visible to others until it is
                  approved by our content moderation team.
                </Type>
              </>
            }
          ></InfoPopover>
        </Type>
      )}
    </div>
  )
}

export default CampaignCommentBody
