import React, { useCallback, useRef } from 'react'
import classNames from 'classnames'
import { Formik, FormikProps, FormikValues } from 'formik'
import { useRouter } from 'next/router'
import { object, string, boolean } from 'yup'
import { ButtonBase, FormError, FormField } from '@/components/common'
import SegmentHandler from '@/services/analytics/SegmentHandler'
import { Comment } from '@/services/api/publicComments'
import { updateUserName, updateFinancialTies } from '@/services/api/user'
import useAuth from '@/services/hooks/useAuth'
import { resolveErrorMessage, ErrorObject } from '@/utils'
import { ArrowUp } from '../svg'

export interface Props {
  campaignSlug: string
  onSubmit: (userId: string, text: string) => Promise<Comment> | undefined
  isResponse?: boolean
  placeholder?: string
  className?: string
}

const nameForm: { name: 'firstName' | 'lastName'; placeholder: string }[] = [
  { name: 'firstName', placeholder: 'Legal First Name' },
  { name: 'lastName', placeholder: 'Legal Last Name' },
]

const validationSchema = object({
  answer: string().min(3, 'Too short').required('Please enter a reply'),
  firstName: string().min(2, 'Too short').required('Please enter a first name'),
  lastName: string().min(2, 'Too short').required('Please enter a last name'),
  financialTies: boolean(),
})

const CampaignCommentForm: React.FC<Props> = ({
  campaignSlug,
  onSubmit,
  isResponse = false,
  placeholder = 'Type to reply',
  className,
}) => {
  const { user } = useAuth()
  const formRef = useRef<FormikProps<FormikValues>>(null)
  const answerRef = useRef<HTMLTextAreaElement>(null)
  const router = useRouter()

  const cleanup = useCallback(() => {
    if (formRef.current) {
      formRef.current.resetForm()
      if (answerRef.current) answerRef.current.style.height = ''
    }
  }, [])

  const resolvedOnSubmit = useCallback(
    async (formValues, formik) => {
      if (!user) return

      try {
        if (!user.firstName || !user.lastName) {
          await updateUserName(
            user.id,
            formValues.firstName,
            formValues.lastName
          )
        }

        if (
          !user.relatedCampaigns.includes(campaignSlug) &&
          formValues.financialTies
        ) {
          await updateFinancialTies(
            [...user.relatedCampaigns, campaignSlug],
            user.id
          )
        }

        if (
          user.relatedCampaigns.includes(campaignSlug) &&
          !formValues.financialTies
        ) {
          await updateFinancialTies(
            user.relatedCampaigns.filter(
              (slug: string) => slug !== campaignSlug
            ),
            user.id
          )
        }

        await onSubmit(user.id, formValues.answer)

        cleanup()
      } catch (e) {
        formik.setSubmitting(false)
        alert(resolveErrorMessage(e as ErrorObject))
      }
    },
    [user, campaignSlug, onSubmit, cleanup]
  )

  const onFocus = useCallback(() => {
    SegmentHandler.track('User Message Started', {
      project: campaignSlug,
      location: router.asPath,
      action_type: 'keyboard',
      message_type: isResponse ? 'response' : 'comment',
    })
  }, [isResponse, router.asPath, campaignSlug])

  return (
    <div className={className}>
      <Formik
        initialValues={{
          answer: '',
          firstName: user?.firstName || '',
          lastName: user?.lastName || '',
          financialTies: user?.relatedCampaigns.includes(campaignSlug) || false,
        }}
        validationSchema={validationSchema}
        validateOnBlur
        validateOnChange
        enableReinitialize
        onSubmit={resolvedOnSubmit}
        innerRef={formRef}
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
          errors,
          submitCount,
          isSubmitting,
          isValid,
        }) => {
          const answerError =
            submitCount > 0 ? (errors.answer as string) || null : null

          const handleAnswerChange = (
            e: React.ChangeEvent<HTMLTextAreaElement>
          ) => {
            setFieldValue('answer', e.target.value)

            if (!answerRef.current) return

            // Reset height first so the textarea can shrink
            answerRef.current.style.height = ''
            // Grow textarea to fit height of content
            answerRef.current.style.height =
              answerRef.current.scrollHeight + 'px'
          }

          return (
            <form onSubmit={handleSubmit} className="relative block">
              <div className="relative">
                {(!user?.firstName || !user?.lastName) && (
                  <div className="mb-4 grid grid-cols-2 gap-4">
                    {nameForm.map(({ name, placeholder }) => (
                      <FormField
                        key={name}
                        name={name}
                        displayName={placeholder}
                      />
                    ))}
                  </div>
                )}

                <textarea
                  ref={answerRef}
                  id="answer"
                  name="answer"
                  className={classNames(
                    'title-xs block h-12 max-h-48 w-full resize-none rounded border p-3 pr-12 focus:outline-none sm:pr-16',
                    {
                      'border-core-error-500': answerError,
                      'border-core-gray-700': !answerError,
                    }
                  )}
                  placeholder={placeholder}
                  value={values.answer}
                  onChange={handleAnswerChange}
                  onFocus={onFocus}
                />

                <ButtonBase
                  className="absolute bottom-2 right-2 flex h-8 w-8 items-center justify-center rounded-full bg-core-black text-white disabled:opacity-20"
                  type="submit"
                  aria-label="submit comment"
                  disabled={isSubmitting || (submitCount > 0 && !isValid)}
                >
                  <ArrowUp />
                </ButtonBase>

                <FormError
                  className="absolute px-0 leading-tight"
                  error={answerError}
                />
              </div>

              {isResponse && values.answer && (
                <ButtonBase
                  type="button"
                  className={classNames(
                    'paragraph-sm py-[11px] leading-tight text-core-error-700',
                    {
                      'mt-2': !answerError,
                      'mt-4': answerError,
                    }
                  )}
                  onClick={cleanup}
                >
                  Cancel
                </ButtonBase>
              )}

              {!isResponse && (
                <div className="flex">
                  <FormField
                    name="financialTies"
                    type="toggle"
                    text="I have financial ties with this campaign"
                    className="mt-6"
                  />
                </div>
              )}
            </form>
          )
        }}
      </Formik>
    </div>
  )
}

export default CampaignCommentForm
